###
.d8888b.  88d888b.  88d888b.
88'  `88  88'  `88  88'  `88
88.  .88  88.  .88  88.  .88
`88888P8  88Y888P'  88Y888P'
ooooooooo~88~oooooo~88~oooooo
          dP        dP

@plugin  	Revelate
@author 	Alex Grozav
@website   	http://pixevil.com
@version 	1.0
@license 	Commercial
###

$('document').ready ->
  $.Velocity.hook $('#advertising'), 'translateY', '100px'
  $.Velocity.hook $('#advertising'), 'opacity', '0'
  setTimeout =>
    $('#advertising').css
      visibility: 'visible'
    .velocity
      translateY: 0
      opacity: 1
    ,
      duration: 700
  , 500

  $('body').revelate()

  # Initialize slidea plugin
  #
  $('#slidea').slidea
    width: 1920
    height: 1200
    autoplay: true
    layout: "fluid"
    parallax: false
    controls: false
    content_parallax: false
    progress: false
    grid:
      rows: 4
      columns: 4
      stagger: 25
    overlap: 0 # SET TO 0 FOR OVERLAPPING
    delay: 5000
    animation:
      in: 'fadeIn, duration 1000'
      out: 'fadeOut, duration 1000'

  $('#toggle-sidebar').on 'click', (e) ->
    $('#content').toggleClass 'open'
    $('#sidebar-wrapper').toggleClass 'open'
    return

  $('#sidebar-wrapper').on 'click', (e) ->
    return unless $(e.currentTarget).is $(e.target)
    if $(e.currentTarget).hasClass 'open'
      $('#content').toggleClass 'open'
      $('#sidebar-wrapper').toggleClass 'open'
    return

  return
